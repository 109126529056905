import PropTypes from 'prop-types'
import React from 'react'
// import PatternSection from "../patternsection/patternsection"
import BannerHome from './../../molecules/bannerhome/bannerhome'
// import CardSection from "./../cardsection/cardsection"
import LeafletCard from './../../../shared/components/molecules/leaflet-card/leaflet-card'
import './homecontent.scss'
import ModalRetails from '../../../shared/components/molecules/modal-retails/modal-retails'
import * as constants from './../../../shared/state/constants/appConstants'
import AuthService from '../../../shared/services/authentication'
import { isAdviser } from '../../../shared/state/utils/isAdviser'
import {
	localStorage,
	sessionStorage,
} from '../../../shared/state/utils/storage'
import useHomeLinkMode from '../../../shared/hooks/useHomeLinkMode'
import AdviserService from '../../../shared/services/adviser'
import MostOutstanding from '../../molecules/most-outstanding/most-outstanding'
import LinesOutstanding from '../../molecules/lines-outstanding/lines-outstanding'
import RecommenderOutstading from '../../molecules/recommender-outstading/recommender-outstading'
import ConsultingOutstading from '../../molecules/consulting-outstading/consulting-outstading'
import ModalHome from '../../molecules/modalHome/modalHome'
import useLocaleMode from '../../../shared/hooks/useLocaleMode'
import useModalGeneric from '../../../shared/hooks/useModalGeneric'
import ModalSamplesAlert from '../../molecules/modalSamplesAlert/modalSamplesAlert'
import useCrudFreeSample from '../../../shared/hooks/useCrudFreeSample'

const HomeContent = props => {
	const {
		bannerArray,
		parametrosHome,
		mostOutstading,
		linesOutstanding,
		recommenderOutstading,
		consultingOutstading,
		modalHomeData,
		isMobile,
		isBannerPaused,
		isLoggedUser,
		leafletContent,
		bannerSostenibilidad
	} = props
	const { locale } = useLocaleMode()
	const { homeLink } = useHomeLinkMode()
	const [modalVisibility, setModalVisibility] = React.useState(false)
	const [modalHomeVisibility, setModalHomeVisibility] = React.useState(
		modalHomeData ? modalHomeData.bannerDesktop.node_locale === locale : false)
	
	const [
		showingModalSamplesAlert,
		showModalSamplesAlert,
		hideModalSamplesAlert,
	] = useModalGeneric(false)
	const [samples, addSample, removeSample, response] = useCrudFreeSample()

	React.useEffect(() => {
		const urlString = window.location.href
		const url = new URL(urlString)
		const section = url.searchParams.get('t')
		if (section) {
			const decode = decodeURIComponent(section)
			const payload = {
				identity: decode,
			}

			AuthService.loginAdviser(payload)
				.then(restData => {
					if (restData.status === constants.successStatus) {
						const userInfo = restData.data.sessionInfo
						localStorage.setItem('tokenData', userInfo.tokenData)
						sessionStorage.removeItem('tokenData')
						sessionStorage.removeItem('userName')
						sessionStorage.removeItem('userData')

						if (isAdviser(userInfo.tokenData.idToken)) {
							if (restData.data.customer) {
								AdviserService.searchUser(
									restData.data.customer,
									res => {
										if (res.status === 200) {
											if (res.data.status === 200) {
												sessionStorage.setItem(
													'userData',
													JSON.stringify(res.data.data)
												)
												localStorage.setItem(
													'userData',
													JSON.stringify(res.data.data)
												)
												sessionStorage.setItem(
													'userNameAdviser',
													`${userInfo.name}`
												)
												window.location.href =
													homeLink + restData.data.redirection
											}
										} else {
											console.log(res)
										}
									},
									error => {
										console.log(error)
									}
								)
							} else {
								sessionStorage.setItem('userNameAdviser', `${userInfo.name}`)
								window.location.href = homeLink + restData.data.redirection
							}
						}
					}
				})
				.catch(err => console.log(err))
		}
	}, [])

	React.useEffect(() => {
		localStorage.setItem('previousPageRegister', 'fromHome')
	}, [])

	React.useEffect(() => {
		if (
			response &&
			response.status === 400 &&
			response.errorCode === 'DEM068'
		) {
			showModalSamplesAlert()
		}
	}, [response])

	let bannerSostenibilidadImages = [
		bannerSostenibilidad.bannerDesktop, 
		bannerSostenibilidad.bannerMobile
	];

	return (
		<article>
			<BannerHome
				isBannerPaused={isBannerPaused}
				array={bannerArray}
				speedtime={parametrosHome.velocidad_rotacion}
				speedchangetime={parametrosHome.tiempo_rotacion}
				ismobile={isMobile}
			/>
			<MostOutstanding outstadings={mostOutstading} ismobile={isMobile} />
			<LinesOutstanding
				linesOustainInfo={linesOutstanding}
				ismobile={isMobile}
				siteUrl={homeLink}
			/>
			<RecommenderOutstading
				recommenderOutstading={recommenderOutstading}
				redireccionDelDestacadoDelRecomendador={
					props.redireccionDelDestacadoDelRecomendador
				}
				isMobile={isMobile}
			/>
			<ConsultingOutstading
				consultingOutstading={consultingOutstading}
				ismobile={isMobile}
			/>
			{ process.env.LOCALE === 'es-CO' && (
				<RecommenderOutstading
					recommenderOutstading={bannerSostenibilidadImages}
					enlace={bannerSostenibilidad.enlace}
					isMobile={isMobile}
				/>)
			}	
			{/* <CardSection
        setModalVisibility={setModalVisibility}
        array={cardsArray}
        retail={retailLogo}
        ismobile={isMobile}
      /> */}
			{/* {locale === "es-CO" ||
      locale === "es-PR" ||
      locale === "es-DO" ||
      locale === "es-PE" ? (
        // <PatternSection array={patternArray} ismobile={isMobile} />
      ) : null} */}
			{isLoggedUser ? null : (
				<div className='f-leaftlet-content'>
					<LeafletCard
						isMobile={isMobile}
						leafletContent={leafletContent}
						isHome={true}
					/>
				</div>
			)}
			{modalVisibility && (
				<ModalRetails
					hideAction={() => {
						setModalVisibility(false)
					}}
				/>
			)}
			{modalHomeVisibility && (
				<ModalHome
					hideAction={() => setModalHomeVisibility(false)}
					modalHomeData={modalHomeData}
				/>
			)}
			{showingModalSamplesAlert &&
				(
					<ModalSamplesAlert
						hideAction={hideModalSamplesAlert}
						actionAlert={() =>
							(window.location.href = homeLink + 'mi-cuenta/datos-personales')
						}
					/>
				)}
		</article>
	)
}

HomeContent.propTypes = {
	parametrosHome: PropTypes.object.isRequired,
	bannerArray: PropTypes.array.isRequired,
	// cardsArray: PropTypes.array.isRequired,
	// patternArray: PropTypes.array.isRequired,
	isMobile: PropTypes.bool.isRequired,
	isLoggedUser: PropTypes.bool.isRequired,
	isBannerPaused: PropTypes.bool.isRequired,
	leafletContent: PropTypes.object.isRequired,
}

export default HomeContent
