import { axiosInstance } from './axiosInstance'
import * as constants from '../state/constants/apiConstants'

class AuthService {
	static async checkEmail(data) {
		const resp = await axiosInstance.post(constants.REGISTER_EMAIL, data)
		return resp.data
	}

	static async login(data) {
		const resp = await axiosInstance.post(constants.REGISTER_CELLPHONE, data)
		return resp.data
	}

	static async forgetEmail(data) {
		const resp = await axiosInstance.put(constants.REGISTER_USER_PATH, data)
		return resp.data
	}

	static async forgetPassword(data) {
		const resp = await axiosInstance.put(constants.REGISTER_PATH, data)
		return resp.data
	}

	static async changeTempPassword(payload, token) {
		const resp = await axiosInstance.post(constants.CHANGE_PASSWORD, payload, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
		})
		return resp.data
	}

	static async userInfo(token) {
		const resp = await axiosInstance.get(constants.ACCOUNT_TOTAL_POINTS, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
		})
		return resp.data
	}

	static async recoveryPassword(email) {
		const resp = await axiosInstance.get(constants.RECOVERY_PASSWORD + email)
		return resp.data
	}

	static async loginUser(payload) {
		const resp = await axiosInstance.post(constants.AUTH_LOGIN, payload)
		return resp.data
	}

	static async sendNewPass(payload, config) {
		const resp = await axiosInstance.post(
			constants.RECOVERY_NO_OTP,
			payload,
			config
		)
		return resp.data
	}

	static async validateUserExists(payload) {
		const resp = await axiosInstance.post(constants.AUTH_EMAIL, payload)
		return resp.data
	}

	static async loginAdviser(payload) {
		const resp = await axiosInstance.post(constants.CRM_LOGIN, payload)
		return resp.data
	}

	static async getUserInfoByDocument(params) {
		const resp = await axiosInstance.get(constants.GET_USER_INFO_BY_DOCUMENT, {
			params,
		})
		return resp.data
	}
}

export default AuthService
