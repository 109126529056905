import React from 'react'
import Img from 'gatsby-image'
import './recommender-outstading.scss'
import useHomeLinkMode from '../../../shared/hooks/useHomeLinkMode'
// import { BLOCKS } from "@contentful/rich-text-types";
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
// import Button from '../../../shared/components/atoms/Button/Button';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../shared/utils/userInfo'

const RecommenderOutstading = props => {
	const { homeLink } = useHomeLinkMode()
	// const options = {
	//     renderNode: {
	//         [BLOCKS.PARAGRAPH]: (node, next) => <p className="f-recommender-outstading-body-text" >{next}</p>
	//     }
	// };

	const userInfo=dataLayerUserInfo()
	const dataLaterBanner = () => {
		let loc=''
		if (typeof window !== 'undefined') {
			loc= window.location.href
		}
		pushDataLayerEvent({event: 'Interacciones - Banners',
		category: 'banner_secundario',
		action: 'click',
		label: 'Recomendador de producto',
		banner_name: 'Recomendador de producto',
		location: loc, 
		user_data: userInfo.user_data? userInfo.user_data: '',
		log_status: userInfo.log_status,})
	}
	const url = `${homeLink}${props.redireccionDelDestacadoDelRecomendador || props.enlace}`;

	return (
		<div className='f-recommender-outstading-container'>
			<a
				className='f-recommender-outstading'
				href={url}
				onClick={()=>{
					dataLaterBanner()
				}}
			>
				{/* <div className="f-recommender-outstading-body">
                    {documentToReactComponents(props.recommenderOutstading.cuerpo.json, options)}
                    <Button text={props.recommenderOutstading.cta} type="button" classname="f-button f-button--success" onClickAction={() => { window.location.href = props.recommenderOutstading.ctaRedireccion }} />
                </div> */}
				<Img
					className='f-recommender-outstading-image'
					fluid={props.recommenderOutstading[props.isMobile ? 1 : 0].fluid}
					alt={props.recommenderOutstading[props.isMobile ? 1 : 0].description}
				/>
			</a>
		</div>
	)
}

export default RecommenderOutstading
