import React from 'react'
import AlertMessagge from '../alertMessagge/alertMessagge'
import Button from '../../../shared/components/atoms/Button/Button'
import './modalSamplesAlert.scss'
import WarningIcon from '../../../shared/assets/images/icon-advertencia.svg'
import LayoutModal from '../../../shared/components/organisms/layoutModal/layout-modal'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'

const ModalSamplesAlert = ({ actionAlert }) => {
	return (
		<LayoutModal>
			<div className='f-alert-wrapper f-modal-samples-alert'>
				<AlertMessagge
					icon={<img src={WarningIcon} alt='warning-icon' />}
					messagge='¡Lo sentimos!'
					description='La última muestra solicitada por el usuario se encuentra en estado devolución, debes actualizar datos.'
					type='primary'
					button={
						<Button
							type='button'
							text={convertText('ACTUALIZAR TUS DATOS')}
							onClickAction={() => {
								actionAlert()
							}}
							classname='f-button f-button--primary'
						/>
					}
				/>
			</div>
		</LayoutModal>
	)
}

export default ModalSamplesAlert
