import * as React from 'react'
import { HomeUpdateData } from './home-update-data/home-update-data'
import { LoginUpdateData } from './login-update-data/login-update-data'
import { FormUpdateData } from './form-update-data/form-update-data'
import { HabeasDataForm } from './habeas-data-form/habeas-data-form'
import { ThanksUpdateData } from './thanks-update-data/thanks-update-data'
import SessionService from '../../../services/session'
import EventService from '../../../services/event'
import {
	addNoScroll,
	removeNoScroll,
} from '../../../state/utils/overflowHelper'
import './update-data-campaing.scss'

const EVENT_CODE = 'EV0003'

const updateDataCampaing = () => {
	const [formStep, setFormStep] = React.useState(null)

	const onClose = () => {
		setFormStep(null)
	}

	const handleFirstStep = () => {
		if (SessionService.getIdTokenData()) {
			setFormStep('formUpdateData')
		} else {
			setFormStep('login')
		}
	}

	const userHasParticipation = async () => {
		const config = {
			headers: {
				Authorization: SessionService.getIdTokenData(),
			},
		}
		const res = await EventService.getParticipationEventWithCode({
			eventCode: EVENT_CODE,
			config,
		})
		if (
			res.status === 200 &&
			res.data?.dataPolicy &&
			res.data?.participationConcourse
		) {
			return true
		}
		return false
	}

	const onLoginSuccess = async () => {
		userHasParticipation().then(hasParticipation => {
			if (hasParticipation) {
				setFormStep('registeredUser')
			} else {
				setFormStep('formUpdateData')
			}
		})
	}

	const transversalProps = {
		setFormStep,
		onClose,
		eventCode: EVENT_CODE,
	}

	const STEP_TO_RENDER = {
		home: (
			<HomeUpdateData {...transversalProps} onClickAction={handleFirstStep} />
		),
		login: (
			<LoginUpdateData {...transversalProps} onLoginSuccess={onLoginSuccess} />
		),
		formUpdateData: <FormUpdateData {...transversalProps} />,
		habeasData: <HabeasDataForm {...transversalProps} />,
		finishModal: (
			<ThanksUpdateData {...transversalProps} onClickAction={onClose} />
		),
		registeredUser: (
			<ThanksUpdateData
				{...transversalProps}
				onClickAction={onClose}
				title='Ya te encuentras registrado en esta actividad'
			/>
		),
	}[formStep]

	React.useEffect(() => {
		addNoScroll()
		formStep === null && removeNoScroll()

		return () => removeNoScroll()
	}, [formStep])

	React.useEffect(() => {
		if (SessionService.getIdTokenData()) {
			userHasParticipation().then(hasParticipation => {
				if (!hasParticipation) {
					setFormStep('home')
				}
			})
		} else {
			setFormStep('home')
		}
	}, [])

	return (
		formStep !== null && (
			<div className='f-update-data-campaing'>{STEP_TO_RENDER}</div>
		)
	)
}

export default updateDataCampaing
