import React from 'react'
import Slider from 'react-slick'
import './lines-outstanding.scss'
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../shared/utils/userInfo'


const ProductsOutstanding = props => {
	const {
		siteUrl: urlRedirect,
		ismobile,
		linesOustainInfo: { lines, titleLines },
	} = props


	const userInfo=dataLayerUserInfo()
	const dataLayerLineasProducto = (lineaDeProductos) => {      
			let location=''
			if (typeof window !== 'undefined') {
					location= window.location.href
			}
			pushDataLayerEvent({event: 'Interacciones - Productos',
			category: 'productos',
			action: 'click',
			label: lineaDeProductos,
			location,
			sub_category: 'Líneas de productos', 
			user_data: userInfo.user_data? userInfo.user_data: '',
			log_status: userInfo.log_status,})
	}

	const listItems =
		lines &&
		lines.map((line, index) => {
			return (
				<a
					className={'f-carousel-line-pos-' + index}
					key={index}
					href={
						urlRedirect +
						'productos/lineas-de-productos/' +
						(line.slug ? line.slug : '')
					}
					onClick={() => {
						dataLayerLineasProducto(line.tituloTarjetaHome)
					}}
				>
					<div
						className='f-section-line-item'
						style={{ '--line-color': line.colorPrincipal }}
					>
						<img
							className='f-section-line-item-img'
							src={line.imagenesTarjetaHome[0]?.file.url}
							alt={line.imagenesTarjetaHome[0]?.description}
							loading='lazy'
						/>
						<h3 className='f-line-title'>{line.tituloTarjetaHome}</h3>
						<h3 className='f-line-title f-line-title--subtitle'>
							{line.tituloTarjetaHome2}
						</h3>
						<p className='f-line-description'>{line.descripcionTarjetaHome}</p>
					</div>
				</a>
			)
		})

	const SlideArrow = props => {
		const { onClick, type } = props
		return <span className={'f-slider-lines-' + type} onClick={onClick} />
	}

	const settings = {
		dots: false,
		// infinite: true,
		infinite: ismobile && lines.length !== 1 ? true : !(lines.length <= 4),
		slidesToShow: 4,
		slidesToScroll: 1,
		initialSlide: 0,
		nextArrow: <SlideArrow type='next' />,
		prevArrow: <SlideArrow type='prev' />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	}

	return (
		<div className='f-section-lines-outstanding-container'>
			<h3 className='f-section-lines-outstanding-title'>{titleLines}</h3>
			<Slider {...settings} className='f-section-lines-outstanding'>
				{listItems}
			</Slider>
		</div>
	)
}

export default ProductsOutstanding
