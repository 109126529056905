import { graphql } from 'gatsby'
import React from 'react'
import HomeContent from '../components/organisms/homecontent/homecontent'
import Layout from '../shared/components/organisms/layout/layout'
import UpdateDataCampaing from '../shared/components/organisms/update-data-campaing/update-data-campaing'
import useLocaleMode from '../shared/hooks/useLocaleMode'
import useMobileMode from '../shared/hooks/useMobileMode'
import SEO from './../shared/components/seo'
import './../shared/_shared/Index.scss'
import useSessionState from '../shared/hooks/useSessionState'

const IndexPage = props => {
	const { locale } = useLocaleMode()
	const { isMobile } = useMobileMode()
	const [isBannerPaused, setBannerPaused] = React.useState(false)
	const { isLoggedUser } = useSessionState()
	const { allContentfulTenaHome, allContentfulTenaRegistroV2 } = props.data
	const isActiveUpdateDataCampaing = false

	return (
		<Layout title={allContentfulTenaHome.nodes[0].metaTitulo} handleBannerPaused={setBannerPaused} isMobile={isMobile}>
			<SEO
				title={allContentfulTenaHome.nodes[0].metaTitulo}
				description={
					allContentfulTenaHome.nodes[0].metaDescripcion.metaDescripcion
				}
				imge={
					allContentfulTenaHome.nodes[0].banner[0]?.bannerDesktop?.fluid.src
				}
				lang={locale}
			/>
			<HomeContent
				bannerSostenibilidad={allContentfulTenaHome.nodes[0].destacadoSostenibilidadBanner}
				isBannerPaused={isBannerPaused}
				parametrosHome={
					allContentfulTenaHome.nodes[0].parametrosBannerPrincipal
				}
				bannerArray={allContentfulTenaHome.nodes[0].banner}
				retailLogo={allContentfulTenaHome.nodes[0].compraEnLineaLogo}
				leafletContent={allContentfulTenaRegistroV2.nodes[0]}
				mostOutstading={{
					outstading: allContentfulTenaHome.nodes[0].pautaDestacada,
					articles: allContentfulTenaHome.nodes[0].articulosDestacados,
				}}
				linesOutstanding={{
					lines: allContentfulTenaHome.nodes[0].referenciasLineasDeProductos,
					titleLines: allContentfulTenaHome.nodes[0].tituloLineasDeProductos,
				}}
				recommenderOutstading={
					allContentfulTenaHome.nodes[0].destacadoDelRecomendador
				}
				redireccionDelDestacadoDelRecomendador={
					allContentfulTenaHome.nodes[0].redireccionDelDestacadoDelRecomendador
				}
				consultingOutstading={{
					info: isMobile
						? allContentfulTenaHome.nodes[0].informacionConsultorioVirtualMobile
						: allContentfulTenaHome.nodes[0].informacionConsultorioVirtual,
					images: allContentfulTenaHome.nodes[0].imagenConsultorioVirtual,
					cta: allContentfulTenaHome.nodes[0].ctaTituloConsultorios,
					ctaRedirection:
						allContentfulTenaHome.nodes[0].ctaRedireccionConsultorios,
				}}
				modalHomeData={allContentfulTenaHome.nodes[0].imagenModal}
				isMobile={isMobile}
				isLoggedUser={isLoggedUser}
			/>
			{isActiveUpdateDataCampaing && locale === 'es-CO' && (
				<UpdateDataCampaing />
			)}
		</Layout>
	)
}

export const query = graphql`
	query GET_CONTENT($language: String!) {
		allContentfulTenaHome(filter: { node_locale: { eq: $language } }) {
			nodes {
				informacionConsultorioVirtual {
					json
				}
				informacionConsultorioVirtualMobile {
					json
				}
				imagenConsultorioVirtual {
					fluid(maxWidth: 1920, quality: 80) {
						src
						srcSet
						base64
						aspectRatio
						sizes
					}
					description
				}
				ctaTituloConsultorios
				ctaRedireccionConsultorios
				destacadoDelRecomendador {
					file {
						url
					}
					title
					description
					fluid(maxWidth: 1920, quality: 80) {
						src
						srcSet
						base64
						aspectRatio
						sizes
					}
				}
				destacadoSostenibilidadBanner {
					bannerDesktop {
						file {
							url
						}
						fluid(maxWidth: 1920, quality: 80) {
							src
							srcSet
							base64
							aspectRatio
							sizes
						}
					}
					bannerMobile {
						file {
							url
						}
						fluid(maxWidth: 1920, quality: 80) {
							src
							srcSet
							base64
							aspectRatio
							sizes
						}
					}
					enlace
				}
				redireccionDelDestacadoDelRecomendador
				tituloLineasDeProductos
				referenciasLineasDeProductos {
					titulo
					slug
					tituloTarjetaHome
					tituloTarjetaHome2
					descripcionTarjetaHome
					colorPrincipal
					imagenesTarjetaHome {
						title
						description
						file {
							url
						}
						fluid(maxWidth: 500, quality: 90) {
							src
							srcSet
							base64
							aspectRatio
							sizes
						}
					}
				}
				pautaDestacada {
					tipoDeLaPauta
					imagenes {
						fluid(maxWidth: 1920, quality: 80) {
							src
							srcSet
							base64
							aspectRatio
							sizes
						}
						description
						title
					}
					cuerpo {
						json
					}
					cta
					ctaRedireccion
				}
				articulosDestacados {
					fechaDePublicacion
					slug
					imagenArticuloMiniatura {
						description
						title
						file {
							url
						}
						fluid(maxWidth: 1920, quality: 80) {
							src
							srcSet
							base64
							aspectRatio
							sizes
						}
					}
					titulo
					categoria {
						slug
						nombreDeEtiqueta
					}
					tipoDelArticulo {
						nombreDeEtiqueta
						imagen {
							file {
								url
							}
						}
					}
				}
				parametrosBannerPrincipal {
					tiempo_rotacion
					velocidad_rotacion
				}
				compraEnLineaRedireccion
				compraEnLineaLogo {
					fluid(maxWidth: 500, quality: 90) {
						src
						srcSet
						base64
						aspectRatio
						sizes
					}
					description
				}
				banner {
					bannerDesktop {
						fluid(maxWidth: 1920, quality: 80) {
							src
							srcSet
							base64
							aspectRatio
							sizes
						}
						description
						title
					}
					bannerMobile {
						fluid(maxWidth: 1920, quality: 80) {
							src
							srcSet
							base64
							aspectRatio
							sizes
						}
						description
						title
					}
					cta
					colorTexto
					descripcionImagen
					enlace
					nombre
					localizacionTexto
				}
				metaTitulo
				metaDescripcion {
					metaDescripcion
				}
			}
		}
		allContentfulTenaRegistroV2(
			filter: {
				titulo: { eq: "Registro > Pauta" }
				node_locale: { eq: $language }
			}
		) {
			nodes {
				titulo
				banner {
					description
					fluid(maxWidth: 1200, quality: 100) {
						src
						srcSet
						base64
						aspectRatio
						sizes
					}
				}
				informacionPrincipal {
					json
				}
				informacionPrincipalTitulo {
					json
				}
				botonSiguiente
				botonFinalizar
				inputCorreoElectronico
				inputNombres
				inputCelular
				InputApellidos
				registroProspectoInscripcionExitosa {
					json
				}
				registroProspectoYaInscrito {
					json
				}

				registroProspectoRecomendados {
					nombreDeEtiqueta
					redireccion
					imagen {
						description
						file {
							url
						}
					}
				}
			}
		}
	}
`

export default IndexPage
