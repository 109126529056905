import SessionService from './session'
import { axiosInstance } from './axiosInstance'
import {
	SHOW_SAMPLES_SENT,
	SEND_SAMPLES,
	SEND_SAMPLES_TEMPORAL,
	GET_MY_ORDERS,
	GET_FOR_ID_ORDERS,
	ADD_USERS_CART_FORGOTTEN,
	DELETE_USERS_CART_FORGOTTEN,
	VERIFY_COVERAGE,
} from '../state/constants/apiConstants'

const SamplesService = {

	verifyCoverageSamples: async (city, success, error) => {
			return await axiosInstance.get(VERIFY_COVERAGE , {
				params: {
					City: city
				},
				headers: {
					'Content-Type': 'application/json',
				},
			})
	},

	addUsersCartForgotten: async (error, success, payload) => {
		try {
			await axiosInstance.post(ADD_USERS_CART_FORGOTTEN, payload,{
				headers: {
					'Content-Type': 'application/json',
					Authorization: SessionService.getIdTokenData(),
				},
			})
			success()
		} catch (_error) {
			error(_error)
		}
	},
	deleteUsersCartForgotten: async error => {
		try {
			await axiosInstance.delete(DELETE_USERS_CART_FORGOTTEN, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: SessionService.getIdTokenData(),
				},
			})
		} catch (_error) {
			error(_error)
		}
	},
	getSamplesSent: async (success, error) => {
		try {
			const response = await axiosInstance.get(SHOW_SAMPLES_SENT, {
				params: {
					email: SessionService.getUserData().email,
				},
				headers: {
					'Content-Type': 'application/json',
					Authorization: SessionService.getIdTokenData(),
				},
			})
			success(response)
		} catch (_error) {
			error(_error)
		}
	},
	getSamplesSentPoll: async (email, success, error) => {
		try {
			const response = await axiosInstance.get(SHOW_SAMPLES_SENT, {
				params: {
					email,
				},
				headers: {
					'Content-Type': 'application/json',
					Authorization: SessionService.getIdTokenData(),
				},
			})
			success(response)
		} catch (_error) {
			error(_error)
		}
	},
	
	sendSamples: async (samples, image, success, error) => {
		try {
			const data = {
				email: SessionService.getUserData().email,
				listProductDto: samples,
				image,
			}
			success(
				await axiosInstance.post(SEND_SAMPLES, data, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: SessionService.getIdTokenData(),
					},
				})
			)
		} catch (_error) {
			error(_error)
		}
	},
	sendSamplesTemporal: async (
		email,
		indicative,
		samples,
		image,
		success,
		error
	) => {
		try {
			const data = {
				email: email.toLowerCase(),
				indicative,
				listProductDto: samples,
				image,
			}
			success(
				await axiosInstance.post(SEND_SAMPLES_TEMPORAL, data, {
					headers: {
						'Content-Type': 'application/json',
					},
				})
			)
		} catch (_error) {
			error(_error)
		}
	},
	getMyOrders: async (success, error) => {
		try {
			const response = await axiosInstance.get(GET_MY_ORDERS, {
				params: {
					email: SessionService.getUserData().email,
				},
				headers: {
					'Content-Type': 'application/json',
					Authorization: SessionService.getIdTokenData(),
				},
			})
			success(response)
		} catch (_error) {
			error(_error)
		}
	},
	getForIdOrder: async (idOrder, success, error) => {
		try {
			const response = await axiosInstance.get(GET_FOR_ID_ORDERS, {
				params: {
					sampleId: idOrder,
				},
				headers: {
					'Content-Type': 'application/json',
					Authorization: SessionService.getIdTokenData(),
				},
			})
			success(response)
		} catch (_error) {
			error(_error)
		}
	},
}

export default SamplesService
