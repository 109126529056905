import React, { useEffect } from 'react'
import Img from 'gatsby-image'
import useLocaleMode from '../../../hooks/useLocaleMode'
import { useStaticQuery, graphql } from 'gatsby'
import ModalRegisterProspect from '../modal-register-prospect/modal-register-prospect'
import { pushDataLayerEvent } from '../../../utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../utils/userInfo'
import './leaflet-card.scss'

const LeafletCard = props => {
	const {
		allContentfulTenaRegistroV2: { nodes },
	} = useStaticQuery(graphql`
		{
			allContentfulTenaRegistroV2(
				filter: { titulo: { eq: "Registro > Pauta" } }
			) {
				nodes {
					node_locale
					titulo
					banner {
						description
						fluid(maxWidth: 1200, quality: 100) {
							src
							srcSet
							base64
							aspectRatio
							sizes
						}
					}
					botonFinalizar
					registroProspectoInscripcionExitosa {
						json
					}
				}
			}
		}
	`)
	const { locale } = useLocaleMode()
	const filterContent = nodes.filter(node => node.node_locale === locale)[0]
	const leafletContent = props.leafletContent || filterContent
	const [isModalProspect, setIsModalProspect] = React.useState(false)
	const userInfo=dataLayerUserInfo()
	const dataLaterBanner = () => {
		let loc=''
		if (typeof window !== 'undefined') {
			loc= window.location.href
		}
		pushDataLayerEvent({event: 'Interacciones - Banners',
		category: 'banner_secundario',
		action: 'click',
		label: 'Inscripción Prospecto',
		banner_name: 'Inscripción Prospecto',
		location: loc, 
		user_data: userInfo.user_data? userInfo.user_data: '',
		log_status: userInfo.log_status,})
	}

	useEffect(() => {
		if (localStorage.getItem("dataUserRegisteredProspect")) {
			setIsModalProspect(true)
		}
	}, [])

	return (
		<>
			<div className='f-leaflet-card' onClick={() => {
				setIsModalProspect(true)
				dataLaterBanner()
			}}>
				<Img
					fluid={leafletContent.banner[props.isMobile ? 1 : 0].fluid}
					className='f-leaflet-card__image-full'
				/>
			</div>

			{isModalProspect && (
				<ModalRegisterProspect
					buttonProspect={leafletContent.botonFinalizar}
					hideAction={() => setIsModalProspect(false)}
					showMessageSuccess={true}
					modalSuccessInfo={leafletContent.registroProspectoInscripcionExitosa}
				/>
			)}
		</>
	)
}

export default LeafletCard
