import React from 'react'
import Img from 'gatsby-image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { MONTHS } from '../../../shared/state/constants/appConstants'
import Button from '../../../shared/components/atoms/Button/Button'
import './most-outstanding.scss'
import useHomeLinkMode from '../../../shared/hooks/useHomeLinkMode'
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../shared/utils/userInfo'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'

const MostOutstanding = props => {
	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, next) => (
				<p className='f-outstading-text'>{next}</p>
			),
		},
	}
	const { homeLink } = useHomeLinkMode()

	const userInfo=dataLayerUserInfo()
	const dataLaterArticulo = (titulo,categoria,tipoArt, link) => {
		let loc=''
		if (typeof window !== 'undefined') {
			loc= window.location.href
		}
		pushDataLayerEvent({event: 'Interacciones - Articulos',
		category: 'academia tena',
		action: 'click',
		label: titulo,
		article_name: titulo,
		sub_category:categoria,
		location: loc, 
		article_type:tipoArt,
		user_data: userInfo.user_data? userInfo.user_data: '',
		log_status: userInfo.log_status,})

		pushDataLayerEvent({
			event: 'article_click',
			link_text: titulo,
			link_url: link,
			article_name: titulo
		})
	}

	return (
		<div className='f-section-mostoutstading-container'>
			<div className='f-section-mostoutstading'>
				<h3 className='f-section-mostoutstading-title'>Lo más destacado</h3>
				<div className='f-section-mostoutstading-outstading'>
					<Img
						className='f-section-mostoutstading-outstading-image'
						fluid={
							props.outstadings.outstading.imagenes[props.ismobile ? 1 : 0]
								.fluid
						}
						alt={
							props.outstadings.outstading.imagenes[props.ismobile ? 1 : 0]
								.description
						}
					/>
					{props.outstadings.outstading.tipoDeLaPauta !== 'Solo imágen' && (
						<div className='f-section-mostoutstading-outstading-body'>
							<div>
								{documentToReactComponents(
									props.outstadings.outstading.cuerpo.json,
									options
								)}
							</div>
							<Button
								type='button'
								text={convertText(props.outstadings.outstading.cta)}
								classname='f-button f-button--primary'
								onClickAction={() => {
									window.location.href =
										props.outstadings.outstading.ctaRedireccion
								}}
							/>
						</div>
					)}
				</div>
				<div className='f-section-mostoutstading-articles'>
					{props.outstadings.articles.map((article, index) => {
						const realDate = new Date(article.fechaDePublicacion)
						const newDate = `${realDate.getDate()} de ${
							MONTHS[realDate.getMonth()]
						} ${realDate.getFullYear()}`
						const articleCategory = article.categoria
							? article.categoria[0]
							: null

						return (
							<a
								key={index}
								className='f-outstading-card'
								href={
									homeLink + 'academia-tena/' + (article.slug || '')
								}
								onClick={() => {
									dataLaterArticulo(article.titulo,article.categoria[0]?.nombreDeEtiqueta,article.tipoDelArticulo?.nombreDeEtiqueta||'', homeLink + 'academia-tena/' + (article.slug || ''))
								}}
							>
								<div className='f-outstading-card-image-container'>
									<span className='f-outstading-card-tag'>
										{article.tipoDelArticulo && (
											<img
												className='f-outstading-card-tag-image'
												src={article.tipoDelArticulo?.imagen.file.url}
												loading='lazy'
											/>
										)}
									</span>
									<Img
										className='f-outstading-card-image'
										fluid={article.imagenArticuloMiniatura.fluid}
										alt={article.imagenArticuloMiniatura.description}
									/>
								</div>
								<div className='f-outstading-card-body'>
									{articleCategory && (
										<span>
											<label>{articleCategory.nombreDeEtiqueta}</label>
										</span>
									)}
									<h4>{article.titulo}</h4>
									<p>
										{newDate} <br />
										{article.tipoDelArticulo?.nombreDeEtiqueta}
									</p>
								</div>
							</a>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default MostOutstanding
