import React, { useEffect } from "react";
import LayoutModal from "../../../shared/components/organisms/layoutModal/layout-modal";
import { addNoScroll, removeNoScroll } from "../../../shared/state/utils/overflowHelper";
import useMobileMode from "../../../shared/hooks/useMobileMode";
import Img from 'gatsby-image'
import './modalHome.scss'

const ModalHome = props => {
    const { hideAction, modalHomeData } = props
    const { enlace, bannerDesktop, bannerMobile } = modalHomeData
    const { isMobile } = useMobileMode()

    useEffect(() => {
        addNoScroll()
        return () => {
            removeNoScroll()
        }
    }, [])

    const closeModal = () => {
        hideAction()
    }

    return (
        <LayoutModal>
            <div className="f-modal-home">
                <a
                    className='f-modal-home-icon'
                    onClick={ () => closeModal()}
                >
					X
				</a>
                <div className="f-modal-home-body">
                    {enlace
                        ? (<a href={enlace} target='_blank' rel="noreferrer noopener">
                            {!isMobile
                                ? <Img
                                    fluid={bannerDesktop.fluid}
                                    alt={bannerDesktop.description}
                                />
                                : <Img
                                    fluid={bannerMobile.fluid}
                                    alt={bannerMobile.description}
                                />
                            }
                            </a>
                        )
                        : (!isMobile
                            ? <Img
                                fluid={bannerDesktop.fluid}
                                alt={bannerDesktop.description}
                            />
                            : <Img
                                fluid={bannerMobile.fluid}
                                alt={bannerMobile.description}
                            />
                        )
                    }
                </div>
            </div>
        </LayoutModal>
    )
}

export default ModalHome;