import { useState, useEffect } from 'react'
import { localStorage } from '../state/utils/storage'
import SamplesService from '../services/samples'
import SessionService from '../services/session'

export default function useCrudFreeSample() {
	useEffect(() => {
		if (SessionService.getUserData()) {
			SamplesService.getSamplesSent(
				response => {
					if (response && response.data) {
						setResponse(response.data)
					}
				},
				error => {
					console.log(error)
				}
			)
		}
	}, [])

	const [response, setResponse] = useState()
	const [samples, setSamples] = useState(localStorage.getItem('samples') || [])

	const addSample = sample => {
		const _samples = samples.concat(sample)
		setSamples(_samples)
		localStorage.setItem('samples', _samples)
	}

	const removeSample = (idSample, size) => {
		const _samples = samples.filter(value => {
			const v1 = value.id !== idSample
			if (!v1) {
				const v2 = size ? value.size !== size : false
				return v1 || v2
			} else return v1
		})
		setSamples(_samples)
		localStorage.setItem('samples', _samples)
	}

	return [samples, addSample, removeSample, response]
}
