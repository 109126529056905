import PropTypes from 'prop-types'
import React from 'react'
import Slider from 'react-slick'
import useHomeLinkMode from '../../../shared/hooks/useHomeLinkMode'
import Img from 'gatsby-image'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import './bannerhome.scss'
import SessionService from '../../../shared/services/session'
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../shared/utils/userInfo'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'

const BannerHome = props => {
	const NextArrow = next => {
		const { onClick } = next
		return <span className='f-slider-control-next' onClick={onClick} />
	}

	const isCo = process.env.LOCALE === 'es-CO'
	console.log(isCo)

	const PrevArrow = prev => {
		const { onClick } = prev
		return <span className='f-slider-control-prev' onClick={onClick} />
	}

	const sliderConf = {
		dots: true,
		infinite: true,
		speed: props.speedtime,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: props.speedchangetime,
		className: 'f-slider-pos',
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
	}

	const sliderRef = React.useRef()
	const { homeLink } = useHomeLinkMode()

	React.useEffect(() => {
		props.isBannerPaused
			? sliderRef.current.slickPause()
			: sliderRef.current.slickPlay()
	}, [props.isBannerPaused])

	const captureHref = (e, url, index) => {
		e.preventDefault()
		if (url !== null && url !== '') {
			if (url.includes('http')) {
				window.open(url, '_blank')
			} else {
				window.location.href = homeLink + url
				// if(mobile){
				//     window.location.href = url
				// }else{
				//     if(isVideo){
				//         e.preventDefault();
				//         setModal(index);
				//     }else{
				//         window.location.href = url
				//     }
				// }
			}
		}
	}

	const userInfo=dataLayerUserInfo()
	
	const dataLaterBanner = (option) => {
		let loc=''
		if (typeof window !== 'undefined') {
			loc= window.location.href
		}
		pushDataLayerEvent({event: 'Interacciones - Banners',
		category: 'banner_principal',
		action: 'click',
		label: option,
		banner_name : option,
		location: loc, 
		user_data: userInfo.user_data? userInfo.user_data: '',
		log_status: userInfo.log_status,})
	}
	return (
		<Slider {...sliderConf} ref={sliderRef}>
			{props.array.map((slide, index) => {
				const currentBanner = props.ismobile
					? slide.bannerMobile
						? slide.bannerMobile
						: slide.bannerDesktop
					: slide.bannerDesktop
				const { description } = props.ismobile
					? slide.bannerMobile
						? slide.bannerMobile
						: slide.bannerDesktop
					: slide.bannerDesktop
				const {
					enlace,
					descripcionImagen,
					localizacionTexto,
					cta,
					nombre,
					colorTexto,
				} = slide
				return (
					<a
						rel='noreferrer noopener'
						onClick={e => 
							{captureHref(e, enlace, index)
							dataLaterBanner(description)}
						}
						href={
							enlace &&
							(enlace.includes('http') || enlace === null
								? ''
								: homeLink + enlace)
						}
						key={index}
					>
						<div
							className={`f-description-wrapper f-description-wrapper-${localizacionTexto}`}
						>
							<h2 className={`f-banner-title f-text-color-${colorTexto}`}>
								{nombre}
							</h2>
							{descripcionImagen && (
								<p
									className={`f-banner-description f-text-color-${colorTexto}`}
								>
									{descripcionImagen}
								</p>
							)}
							{cta && !SessionService.getUserData() && (
								<button className='f-btn-promo'>{convertText(cta)}</button>
							)}
						</div>
						<img src={currentBanner.fluid.src} alt={description} />
					</a>
				)
			})}
		</Slider>
	)
}

BannerHome.propTypes = {
	speedtime: PropTypes.number.isRequired,
	speedchangetime: PropTypes.number.isRequired,
	array: PropTypes.array.isRequired,
}

export default BannerHome
