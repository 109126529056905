import React from 'react'
import './alertMessagge.scss'

const AlertMessagge = ({ icon, messagge, description, type, button }) => {
	return (
		<div className={`f-alert f-alert--${type}`}>
			{icon}
			<h2 className='f-h2'>{messagge}</h2>
			<p className='f-p'>{description}</p>
			{button || ''}
		</div>
	)
}

export default AlertMessagge
