import React from 'react'
import Img from 'gatsby-image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import './consulting-outstading.scss'
import Button from '../../../shared/components/atoms/Button/Button'
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../shared/utils/userInfo'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'

const ConsultingOutstading = props => {
	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, next) => (
				<p className='f-consulting-outstading-body-text'>{next}</p>
			),
			[BLOCKS.UL_LIST]: (node, next) => (
				<ul className='f-consulting-outstading-body-list'>{next}</ul>
			),
			[BLOCKS.EMBEDDED_ASSET]: node => (
				<img
					src={
						node.data.target.fields.file[process.env.LOCALE]
							? node.data.target.fields.file[process.env.LOCALE].url
							: node.data.target.fields.file['es-CO'].url
					}
					alt={
						node.data.target.fields.description[process.env.LOCALE]
							? node.data.target.fields.description[process.env.LOCALE]
							: node.data.target.fields.description['es-CO']
					}
					className='f-consulting-outstading-image'
					loading='lazy'
				/>
			),
		},
	}

	const userInf=dataLayerUserInfo()
    const dataLaterConsulting = (link) => {        
		let loc=''
		if (typeof window !== 'undefined') {
			loc= window.location.href
		}
		pushDataLayerEvent({event: 'Interacciones - Asesoría',
		category: 'consultorio',
		action: 'click',
		label: 'Consultar',
		location: loc,
		user_data: userInf.user_data? userInf.user_data: '',
		log_status: userInf.log_status,})

		pushDataLayerEvent({
			event: 'cta_click',
			link_text: 'Consultar',
			link_url: link,
			link_domain: link.split('/')[2].split('www.')[1],
			link_id: '',
			link_classes: 'f-button f-button--primary',
		})
	}
	return (
		<div className='f-consulting-outstading-container'>
			{props.ismobile ? (
				<div className='f-consulting-outstading'>
					<div className='f-consulting-outstading-body'>
						{documentToReactComponents(
							props.consultingOutstading.info.json,
							options
						)}
						<Button
							text={convertText(props.consultingOutstading.cta)}
							type='button'
							classname='f-button f-button--primary'
							onClickAction={() => {
								dataLaterConsulting(props.consultingOutstading.ctaRedirection)
								window.location.href = props.consultingOutstading.ctaRedirection
							}}
						/>
					</div>
				</div>
			) : (
				<div className='f-consulting-outstading'>
					<div className='f-consulting-outstading-image-container'>
						 <img
							className='f-consulting-outstading-image'
							src={props.consultingOutstading.images[0].fluid.src}
							alt={props.consultingOutstading.images[0].description}
							loading='lazy'
						/>
					</div>
					<div className='f-consulting-outstading-body'>
						{documentToReactComponents(
							props.consultingOutstading.info.json,
							options
						)}
						<Button
							text={convertText(props.consultingOutstading.cta)}
							type='button'
							classname='f-button f-button--primary'
							onClickAction={() => {
								dataLaterConsulting(props.consultingOutstading.ctaRedirection)
								window.location.href = props.consultingOutstading.ctaRedirection
							}}
						/>
					</div>
				</div>
			)}
		</div>
	)
}

export default ConsultingOutstading
